import React from 'react'
import "./not-found.css"
import {useAppearing} from "../../hooks/custom-hooks"

const NotFound = ({appStore}) => {
  useAppearing({appStore, isFullyRendered:true});
  return (
    <div className="not-found">
    <h2>404 - Nie znaleziono</h2>
        <img src="/img/imgNotFound.svg" width="100%" height="500px" alt="Obrazek nie znaleziono treści..."/>
        <h2>Przykro nam. Nie znaleziono treści dla podanego adresu...</h2>
    </div>
  )
}

export default NotFound
