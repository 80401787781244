import React from 'react'
import "./mobile-button-icon.css";

const MobileButtonIcon = ({className, color}) => {
  return (
    <div className={"mobile-icon "+className}>
          <div className="edges edge-top"/>
          <div className="center1"/>
          <div className="center2"/>
          <div className="edges edge-bottom"/>
      </div>

  )
}

export default MobileButtonIcon
