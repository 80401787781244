import React from 'react'
import {useFetchFromDB} from "../../hooks/db-hooks.js"

const Contact = ({showTitle}) => {
  const [contactData,error] = useFetchFromDB({endPoint:"singletons/get/kontakt"});

  const phones = contactData &&  contactData.telefony ? contactData.telefony.map((item,key)=><span key={key}>{item.value + " "}</span>) : "";
  const mails = contactData &&  contactData.email ? contactData.email.map((item,key)=><li key={key}><a href={"mailto:"+item.value} >{" " +item.value+" "}</a></li>) : "";
  const hours = contactData ? contactData.godziny_otwarcia.split("\n").map((line,i)=><div className={line ? "normal" : "empty"} key={i}>{line}</div>):""
  return (
    <div className={"contact"}>
      <div className="info contact-part">
        {showTitle ? <h2>Kontakt</h2> : ""}
        <div className="name">{contactData && contactData.nazwa ? contactData.nazwa :""}</div>
        <div>{contactData &&  contactData.adres ? contactData.adres :""}</div>
        {phones ?
          <div>
            <span>Tel: </span>
            <span>{phones}</span>
          </div>
          : ""}
        {mails ?
          <div>
            <span>Email: </span>
            <ul>{mails}</ul>
          </div>
          : ""}
        </div>
        <div className="opening-hours contact-part">
          {hours}
        </div>
    </div>
  )
}

export default Contact
