import React from 'react'
import {useState} from "react"
import Slider from "../../../common/slider"
import "./banner.css";

import Calendar from "./calendar"
import MobileCalendarSvg from "./mobile-calendar-svg"
import Schelude from "./schelude"
import {useFetchFromDB} from "../../../hooks/db-hooks.js"
import {useDeviceHeight} from "../../../hooks/custom-hooks.js"

const Banner = ({appStore}) => {
  const [dateChecked,setDateChecked] = useState(false);
  const [isHovered,setIsHovered] = useState(false);
  const [hoveredDate,setHoveredDate] = useState();
  const [isVisable,visable] = useState(0);
  const [eventsUnFiltered,error] = useFetchFromDB({endPoint:"collections/get/aktualnosci",filters:{"filter[kalendarz]":true}});
  const events = eventsUnFiltered ? eventsUnFiltered.filter((event)=>Date.parse(event.kalendarz) > Date.now()) : [];
  const [canceled,errorCanceled] = useFetchFromDB({endPoint:"collections/get/odwolane",filters:{populate:1}});
  const deviceHeight = useDeviceHeight();
  const [scheludeEx,error2] = useFetchFromDB({endPoint:"collections/get/zajecia"})

  return (
    <div style={{height:deviceHeight}} className={`banner ${isHovered?"calendar-hovered":"calendar-not-hovered"}${hoveredDate?" date-hovered":""}${isVisable ? " visable" : " not-visble"}${dateChecked?" date-checked":""}`} >

      <div className={"squere-container"}>
          <div className="calendar-container" onPointerLeave={()=>setIsHovered(false)} onPointerEnter={()=>setIsHovered(true)}>
            <Calendar events={events} setHoveredDate={setHoveredDate} setDateChecked={setDateChecked} dateChecked={dateChecked} scheludeEx={scheludeEx} canceled={canceled}/>
          </div>

      </div>

      <div className="greeting">
        <h1>Witamy w Gąbińskim Klubie Seniora</h1>
      </div>
        <div className="slider-wrapper" >
          <Slider appStore={appStore} endPointName="singletons/get/homepage"/>
          <div className="banner-deco banner-deco1"/>
          <div className="banner-deco banner-deco2"/>
        </div>
      <div className="schelude-area">
        <Schelude canceled={canceled} appStore={appStore} events={events} hoveredDate={hoveredDate} scheludeEx={scheludeEx}/>
      </div>
      <div className="schelude-background"></div>
      <button
        aria-label="Pokaż Kalendarz"
        title="Pokaż kalendarz"
        onClick={()=>{
          setHoveredDate(null)
          if(dateChecked) setDateChecked(false);
          visable((cls)=>cls!==2 ? 2 : 0)
        }}
        className="calendar-button"><MobileCalendarSvg />
      </button>
      {error ? error : ""}

    </div>
  )
}

export default Banner
