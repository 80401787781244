import React,{useState,useEffect} from 'react'
import "./controls.css";

const Controls = ({countSlides,setCurrentActive,currentActive}) => {
const [controls,setControls] = useState();
  useEffect(()=>{
    if(countSlides){
      const empty = Array(countSlides).fill({});
      setControls(empty.map((item, i) => <button key={i} onClick={
        (e)=>{
          e.target.blur();
          setCurrentActive(i)}}
        className={`control ${currentActive===i ? "active":"deactivated"}`}
        aria-label={`Przejdź na ${i+1} slajd`}>
        {i+1}

      </button>
    )
      )
    }
  },[countSlides,setCurrentActive,currentActive])

  return (
    <div className="controls">
    {controls}
    <div className="loader" key={currentActive}></div>
    
    </div>
  )
}

export default Controls
