import React from 'react'
import {useState, useEffect} from 'react'
import "./slide.css";
import DelayLink from "../../../common/delay-link"

import {getImgUrl,getSrcSet} from "../../../utils"

import {useHistory} from "react-router-dom";

const Slide = ({id,slideObject,staticName,isActive,setCurrentActive,slideDuration,countSlides,appStore}) => {
  const [slideState,setSlideState] = useState("loading")
  const history = useHistory();

  useEffect(()=>{
    let timeout2
    if(isActive){
      timeout2 = setTimeout(() => setCurrentActive(active=>(++active)%countSlides), slideDuration);
    }

    return ()=>{
      clearTimeout(timeout2)
    };
  },[countSlides,setCurrentActive,slideDuration,isActive])

  return (
    <div className={`slide slide${id} ${slideState} ${isActive?"active":"deactivated"}`}>
        <div className="content-wrapper">
        {slideState==="error" ? <p>Niestety nie udało się pobrać obrazu</p> : undefined}
        <img
          srcSet={getSrcSet({content:slideObject.obraz})}
          alt="Gąbiński Klub Seniora zaprasza"
          src = {getImgUrl({content:slideObject.obraz})}
          onLoad = {()=>setSlideState("loaded")}
          onError = {(e)=>{
            setSlideState("error")
          }
          }
        />

        <div className="slide-caption">
          <div className="container underlined-links">
          {slideObject.tytul?<h2>{slideObject.tytul}</h2>:""}
          {slideObject.opis?<p>{slideObject.opis}</p>:""}
          {slideObject.link?  <div className="link-wrapper"><DelayLink
            appStore={appStore} history={history}
            delay={500}
            to={slideObject.link}
            inner={<div className="strong-link">
                    SPRAWDŹ <div className="plus">   </div> </div>}>
            </DelayLink></div>:""}
          </div>
        </div>
        </div>

    </div>
  )
}

export default Slide;
