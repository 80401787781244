import React from "react";
import { Link } from "react-router-dom";
import { useRef } from "react";
import { disableScroll } from "../../utils";

const DelayLink = ({
  className,
  history,
  to,
  delay,
  appStore,
  inner,
  ariaLabel,
  sectionId,
  location,
}) => {
  const ref = useRef();

  let section;
  let sectionY = 0;
  return to.substr(0,4) === "www." || to.substr(0, 4) === "http" ? (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      title={ariaLabel}
      onClick={(e) => {
        // e.target.blur();
      }}
    >
      <div className="strong-link">SPRAWDŹ <div className="plus"></div></div>
    </a>
  ) : (
    <Link
      className={className}
      aria-label={ariaLabel}
      title={ariaLabel}
      ref={ref}
      onClick={(e) => {
        ref.current.blur();
        let timeout;
        e.preventDefault();
        if ("/" + (location !== "" ? location : "strona-glowna") !== to) {
          disableScroll();

          appStore.toggleLoading(true);
          timeout = setTimeout(() => {
            appStore.setContentLoaded({ to, sectionId });
            return history.push(to);
          }, delay / 2);
        } else {
          section = document.querySelector("#" + sectionId);
          sectionY = section
            ? section.getBoundingClientRect().top + window.scrollY
            : 0;
          window.scrollTo({ left: 0, top: sectionY - 50, behavior: "smooth" });
        }
        return () => clearTimeout(timeout);
      }}
      to={to}
    >
      {inner}
    </Link>
  );
};

export default DelayLink;
