import React from 'react'
import "./mobile-calendar-svg.css";

const MobileCalendarSvg = () => {
  return (
    <svg viewBox="0 0 100 100" className={"calendar-svg"} xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#fff"
        fillOpacity="0"
        fillRule="nonzero"
        stroke="#e6e6e6"
        strokeDasharray="none"
        strokeDashoffset="0"
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        strokeWidth="3.5"
        d="M86.354 89.216l4.477-.002V10.44H71.418m-6.895 0H35.797m-6.894 0H9.296v78.774h4.963l29.68.02"
        opacity="1"
        paintOrder="markers stroke fill"
      ></path>
      <path
        fill="#fff"
        stroke="#e6e6e6"
        strokeOpacity="1"
        strokeWidth="2"
        d="M9.9 28.468h79.943v0"
      ></path>
      <g
        fill="#fff"
        stroke="#e6e6e6"
        strokeDasharray="none"
        strokeLinejoin="miter"
        strokeMiterlimit="4"
        strokeOpacity="1"
        className="calendar-icon"
        transform="translate(0 -.884)"
      >
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M16.96 52.198h66.519v0"
        ></path>
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M16.96 62.314h66.519v0"
        ></path>
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M16.96 72.43h66.519v0"
        ></path>
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M66.351 42.082v40.465"
        ></path>
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M49.887 42.082v40.465"
        ></path>
        <path
          strokeLinecap="butt"
          strokeWidth="2"
          d="M33.423 42.082v40.465"
        ></path>
        <path
          fillOpacity="0"
          fillRule="nonzero"
          strokeDashoffset="0"
          strokeLinecap="square"
          strokeWidth="3.5"
          d="M45.004 82.33h38.86V42.083h-67.78V82.33h4.125l24.673.01"
          opacity="1"
          paintOrder="markers stroke fill"
        ></path>
      </g>
      <path
        fill="#fff"
        stroke="#e6e6e6"
        strokeOpacity="1"
        strokeWidth="2"
        d="M67.843 1.823v14.465"
      ></path>
      <path
        fill="#fff"
        stroke="#e6e6e6"
        strokeOpacity="1"
        strokeWidth="2"
        d="M32.36 1.823v14.465"
      ></path>
      <path
        fill="#fff"
        stroke="#e6e6e6"
        strokeOpacity="1"
        strokeWidth="2"
        d="M49.887 41.198v40.465"
        className="left-x"
      ></path>
      <path
        fill="#fff"
        stroke="#e6e6e6"
        strokeOpacity="1"
        strokeWidth="2"
        d="M49.887 41.198v40.465"
        className="right-x"
      ></path>
      </svg>

  )
}

export default MobileCalendarSvg
