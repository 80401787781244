import React from "react";
import { useState, useEffect, useRef } from "react";
import Tile from "./tile";
import Loading from "../loading";
import "./news.css";
import { useFetchFromDB } from "../../hooks/db-hooks.js";
import { useRendered } from "../../hooks/custom-hooks.js";
import Error from "../../common/error";

const News = ({
  subTitle,
  endPointName,
  appStore,
  filters,
  numberOfTiles,
  setRendered,
  nullInfo,
}) => {
  const updatedEndPointName = endPointName
    ? endPointName
    : "collections/get/aktualnosci";
  let ticking = useRef(false);
  const newsFilters = { ...filters, "fields[dzien]": "_id" };
  const [countEndPoint, error] = useFetchFromDB({
    endPoint: updatedEndPointName,
    filters: newsFilters,
    noSimple: true,
  });
  const count =
    countEndPoint !== undefined && countEndPoint !== null
      ? countEndPoint.total
      : undefined;
  const [news, setNews] = useState();
  const [loadingState, setLoadingState] = useState("loading");
  const [showMore, setShowMore] = useState(numberOfTiles ? numberOfTiles : 3);
  const [renderedArray, setRenderedArray] = useState("000");
  const [scrollY, setScrollY] = useState(0);
  const setRenderedOne = (number) =>
    setRenderedArray((str) => {
      let stringArray = str.substr(0, number) + "1" + str.substr(number + 1);
      return stringArray;
    });
  const newsRef = useRef(undefined);
  useEffect(() => {
    const handleScroll = () => {
      if (!ticking.current) {
        window.requestAnimationFrame(function() {
          setScrollY(
            (window.scrollY * 2) /
              (window.pageYOffset + newsRef.current.getBoundingClientRect().top)
          );
          ticking.current = true;
        });
     }

      ticking.current = false;
    };
    if(newsRef.current) {
      document.addEventListener("scroll", handleScroll);
    }
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [newsRef,ticking]);

  useRendered({
    renderedObject: count,
    error: error,
    setRendered: setRendered,
  });
  useEffect(() => {
    if (typeof count === "number") {
      const empty = Array(Math.min(showMore, count)).fill({});
      setNews(
        empty.map((item, i) => (
          <Tile
            setRendered={setRenderedOne}
            key={i}
            endPointName={updatedEndPointName}
            orderNr={i}
            appStore={appStore}
            filters={filters}
            count={count}
            setNewsLoading={setLoadingState}
            style={{
              transform: `translate(${
                scrollY < 1
                  ? Math.log(scrollY) *
                    40 *
                    (i * i + 1) *
                    (i % 3 === 0 ? 1 : -1)
                  : 0
              }px, ${
                window.screen.width > 1024 && scrollY < 1
                  ? Math.log(scrollY) *
                    40 *
                    (i * i + 1) *
                    (i % 3 === 0 ? 1 : -1)
                  : 0
              }px)`,
            }}
          />
        ))
      );
    }
  }, [
    setNews,
    count,
    updatedEndPointName,
    showMore,
    appStore,
    filters,
    scrollY,
  ]);
  useEffect(() => {
    if (countEndPoint) setLoadingState("");
  }, [countEndPoint]);

  return count === null || count > 0 || !endPointName ? (
    <div
      ref = {newsRef}
      style={{ opacity: scrollY<1.1 ? scrollY - 0.1 : 1 }}
      className={
        "news " +
        loadingState +
        (renderedArray === "111" ? " render-finish" : "render-pending")
      }
    >
      <h2
        style={{ transform: `translate(${scrollY * 10}px)` }}
        className="news-title"
      >
        {subTitle ? subTitle + " - " : ""}Aktualności
      </h2>
      <div className="news-container">
        {error ? <Error e={error} /> : news}
        <Loading
          className={
            error ? "error" : renderedArray === "111" ? "loaded" : "loading"
          }
        />
      </div>
      {showMore >= count ? (
        ""
      ) : (
        <button
          className="show-all"
          onClick={(e) => {
            setLoadingState("loading");
            e.target.blur();
            setTimeout(() => {
              setShowMore((value) => value + 3);
            }, 300);
          }}
        >
          pokaż więcej
        </button>
      )}
    </div>
  ) : nullInfo ? (
    <div className="news">
      <h2>{subTitle ? subTitle + " - " : ""}Aktualności</h2>
      <p>{nullInfo}</p>
    </div>
  ) : (
    ""
  );
};

export default News;
