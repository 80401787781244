import React from 'react';
import {createPortal} from 'react-dom';
import {useState,useEffect} from "react"
import "./gallery.css"
import MobileButtonIcon from "../mobile-button-icon";
import Loading from "../../common/loading"
import {getImgUrl, getSrcSet} from "../../utils"

const Gallery = ({imgArray, singleImg, isFitted, preventBubble}) => {
  const [currentImg,setCurrentImg] = useState(0);
  const [currentSet,setCurrentSet] = useState(0);
  const [tilesView,setTilesView] = useState(false);
  const [isActive,setIsActive] = useState(false);
  const [isTransitioned,setIsTransitioned] = useState(false);
  const [loadingStateOverview,setLoadingStateOverview] = useState("loading");
  const [loadingStateViewer,setLoadingStateViewer] = useState("loading");
  const imgThumbs =  imgArray ? imgArray.slice(tilesView ? 0 :currentSet*5, tilesView ? imgArray.length : Math.min((currentSet+1)*5, imgArray.length)).map((item,i)=>
  <button
   key={i*(5*currentSet + 1)}
   style={{animationDelay:tilesView ? 0 :(i/3 + "s")}}
   onClick={()=>{
     setCurrentImg(tilesView ? i :i + 5*currentSet)
     setTilesView(false)
     }
   }
   className ="thumb">
  <img


    alt="Miniaturka obrazu"
    src={getImgUrl({content:item, size:"120", isGallery:true})}
   /></button> ) : "";

  useEffect(()=>{
    let timeout1
      setLoadingStateViewer("loading")
      if(isActive){
        timeout1 = setTimeout(()=>setIsTransitioned(true),3)
      }
      else setIsTransitioned(false);
      return ()=>{
        clearTimeout(timeout1)
      };
  },[isActive])
  useEffect(()=>{
      if(currentImg) setLoadingStateViewer("loading")
  },[setLoadingStateViewer,currentImg])
  const src = getImgUrl({content:singleImg ? singleImg : imgArray[currentImg], isGallery:imgArray ? true : false});
  const srcSet = getSrcSet({content:singleImg ? singleImg : imgArray[currentImg], isGallery:imgArray ? true : false});

  return (

    <div className={`gallery-container`}>
      <div aria-label="Podgląd zdjęcia" tabIndex={0} onKeyDown={(e)=>{if(e.key === 'Enter'){setIsActive(true)}}}
      onClick={()=>preventBubble ? undefined :setIsActive(true)} className="gallery-overview">
      <figure>
          <img
            className={`${loadingStateOverview}${singleImg && isFitted?" fitted":""}`}
            srcSet={srcSet}
            alt="Podgląd galerii"
            src={src}
            sizes = {singleImg ? "(max-width: 800px) 600px, (max-width: 1024px) 900px, (max-width: 1500px) 1200px," : undefined}
            onLoad={()=>setLoadingStateOverview("loaded")}
            />
          {singleImg ? singleImg.caption ? <figcaption className="img-caption">{singleImg.caption}</figcaption>:"" : <figcaption className="title"><span>Zobacz</span></figcaption>}
      </figure>
      <Loading className={loadingStateOverview}/>
      </div>
    {  isActive ?
      createPortal(<div className={`gallery-viewer ${isTransitioned?" transitioned":""}${imgArray ?  "" : " is-single"}${tilesView ?  " tiles-view" : ""}${isActive?" active":""}`}>
            <Loading className={`white-color ${loadingStateViewer}`}/>
          <button className="close-btn" aria-label="Zamknij galerię" title="Zamknij galerię" onClick={()=>setIsActive(false)}><MobileButtonIcon color={"#ffffff"} className={"menu-visable"}/></button>

          <figure>
            <img className={loadingStateViewer} alt="Obraz z galerii" srcSet={srcSet} src={src}   onLoad={()=>setLoadingStateViewer("loaded")}/>
            <figcaption className="img-caption">{singleImg ? singleImg.caption : imgArray[currentImg].caption}</figcaption>
          </figure>
          {!singleImg ?
            <div className="control-bar">
              <button aria-label={!tilesView ? "Pokaż wszystkie kafelki" : "Zwiń do wstążki"} title={!tilesView ? "Pokaż wszystkie kafelki" : "Zwiń do wstążki"} className="tiles-btn" onClick={()=>setTilesView((bool)=>!bool)}></button>
              <button aria-label="Poprzedni zestaw zdjęć" title="Poprzedni zestaw zdjęć" className={`prev-slide control ${currentSet===0 || tilesView?"inactive":"active"}`} onClick={()=>setCurrentSet((current)=>current-1)} />
              <div className="gallery-grid-wrapper"><div className="gallery-grid">{imgThumbs}</div></div>
              <button aria-label="Następny zestaw zdjęć" title="Następny zestaw zdjęć" className={`next-slide control ${currentSet===Math.floor((imgArray.length-1)/5) || tilesView ?"inactive":"active"}`} onClick={()=>setCurrentSet((current)=>current+1)}/>

            </div> : ""}

      </div>,document.body) : ""}
    </div>
  )
}

export default Gallery
