import React,{useState,useEffect} from 'react'
import {useFetchFromDB} from "../../hooks/db-hooks.js"
import "./slider.css";
import Loading from "../../common/loading";

import Slide from "./slide"
import Controls from "./controls"

const slideDuration = 7000;

const Slider = ({endPointName,appStore,sliderArray,errorSlider,passError}) => {
  const [endPoint, errorEndPoint] = useFetchFromDB({endPoint:endPointName});
  const error = passError ? errorSlider : errorEndPoint ;
  const sliderData = endPoint && endPoint["slajd"] ? endPoint["slajd"] : sliderArray;
  const countSlides = sliderData ? sliderData.length : undefined;
  const [slides,setSlides] = useState();
  const [currentActive,setCurrentActive] = useState(0);
  useEffect(()=>{
    if(countSlides){
      setSlides(sliderData.map((item, i) => <Slide
      key={i}
      id={i+1}
      isActive={currentActive===i}
      setCurrentActive={setCurrentActive}
      slideDuration={slideDuration}
      countSlides={countSlides}
      slideObject={item ? item.value : undefined}
      appStore={appStore}
      />)
      )
    }
  },[countSlides,sliderData,currentActive,appStore])

  return (
    <div className="slider">
      {countSlides>1 ? <Controls countSlides={countSlides} setCurrentActive={setCurrentActive} currentActive={currentActive}/>:""}
      {!error ? slides:""}
      <Loading className={error?"error":(!sliderData?"loading":"loaded")}/>
      <a className="herb" href="https://www.gabin.pl" target="_blank" rel="noopener noreferrer" title="Miasto i Gmina Gąbin" aria-label="Strona Miasta i Gminy Gąbin"> </a>
    </div>
  )
}

export default Slider;
