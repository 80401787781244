import {useEffect, useState} from 'react';
import qs from "qs";

const fetch_retry = async (url, n) => {
    const controller = new AbortController()
    const requestTimeout = setTimeout(() => controller.abort(), 5000)
    try {
        return await fetch(url,  { signal: controller.signal }).then((response)=>{
          if(response.status!==200) {
            throw new Error("Error:" + response.status)
          }
            else return response;
          })
    } catch(err) {
        clearTimeout(requestTimeout);
        if (n === 1) throw err;
        return await fetch_retry(url,  n - 1);
    }
};

  const useFetchFromDB = ({customContent, endPoint, orderNr, contentName,  filters, noSimple}) => {
    const queryString = qs.stringify({
    ...filters,
    skip:orderNr!==undefined?orderNr:undefined,
    limit:orderNr!==undefined?1:undefined,
    token:process.env.REACT_APP_PUBLIC_TOKEN,
    simple: noSimple? 0 : 1
    });

  const [data,setData] = useState(null);
  const [error,setError] = useState(null);

  useEffect(()=>{
        const slugGenerator = process.env.REACT_APP_HOST_URL+"api/"+endPoint+(queryString?`?${queryString}`:"");

    const fetchData = async () => {
      try{

      const tempData = await fetch_retry(slugGenerator,3).then(response => response.json());

        if(contentName!==undefined) {
          setData(tempData[contentName])
        }
        else setData(tempData);
      }
      catch(er){
        setError(er.message);
      }
    }
    if(customContent) {
        setData(customContent);
    } else if(endPoint) fetchData();

  },[endPoint,contentName, orderNr,queryString, customContent])

  return [data,error];
}

export {useFetchFromDB};
