import React from 'react'
import { useState,useRef } from 'react'
import "./header.css";
import Navigation from "./nav";
import MobileButtonIcon from "../common/mobile-button-icon";
import DelayLink from "../common/delay-link"
import { useHistory, useLocation } from "react-router-dom";

const Header = ({ appStore, setTheme }) => {
  const history = useHistory();
  const location = useLocation().pathname.split("/");

  const [navState, setNavState] = useState(-1);

  const ref1 = useRef();
  const ref2 = useRef();
  return (
    <header ref={ref1}>
          <DelayLink ariaLabel={"Strona główna"}
            appStore={appStore}
            history={history}
            delay={500}
            to="/"
            inner={<div className={"logo"}/>}/>
      <div className={"nav-container"}>
        <Navigation setTheme={setTheme} location={location[1]} setNavState={setNavState} history={history} appStore={appStore} className={"nav-home "+(navState===1?"visable":"hidden")}/>
      </div>
      <button ref={ref2} className={"mobile-btn"} aria-label="Pokaż menu" onClick={()=>{ref2.current.blur(); setNavState(visibility=>-visibility)}}><MobileButtonIcon className={navState===1?"menu-visable":"menu-hidden"}/></button>
    </header>
  )
}

export default Header;
