import React from "react";
import { useEffect, useState } from "react";
import "./loading.css";
import Error from "../../common/error";

const Loading = ({ e, className }) => {
  const [loadingState, setLoadingState] = useState("appeared");

  useEffect(() => {
    if (className === "loading") {
      var time = setTimeout(function () {
        setLoadingState(false);
      }, 10);
    }
    if (className === "loaded") {
      var time2 = setTimeout(function () {
        setLoadingState("fading");
      }, 100);
    }
    return () => {
      clearTimeout(time);
      clearTimeout(time2);
    };
  }, [className]);
  const Shapes = (
    <>
      <div className="loading-shape shape1"></div>
      <div className="loading-shape shape2"></div>
      <div className="loading-shape shape3"></div>
    </>
  );

  return (
    <div
      className={`loading-container ${loadingState ? loadingState : className}`}
    >
      {e || className === "error" ? <Error e={e} /> : Shapes}
    </div>
  );
};

export default Loading;
