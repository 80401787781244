import React from 'react'
import "./calendar.css"
import Day from "./day"
import { useState, useEffect } from "react"


const monthsList = ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"]
// TODO: On hoover najbliżesz zanim załaduje

const getMonthDays = (date) => {
  var d = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return d.getDate();
}

const reduceArray = (array,field) => {
  let arrayString;

  if(array && Array.isArray(array) && array.length>0){
    arrayString = array.length>1 ? array.reduce((itemPrev, itemNext, index) => {
      return (index === 1) ? itemPrev[field] + " " + itemNext[field] : itemPrev + " " + itemNext[field];
    }) : array[0][field];
  } else {
    arrayString=""
  }
  return arrayString
}

const Calendar = ({ setHoveredDate, setDateChecked, events, dateChecked, scheludeEx, canceled }) => {
  const todayDate = new Date();
  const [activeDate, setActiveDate] = useState(todayDate);

  const [Days, setDays] = useState();
  const monthDays = getMonthDays(activeDate);
  const activeDay = activeDate.getDate();


  const nearestEvent = events ? events[0] : null;
  const nearestEventDate = nearestEvent ? nearestEvent.kalendarz : null;
  let eventsString = reduceArray(events,"kalendarz");
  let scheludedDays = reduceArray(scheludeEx,"dzien");
  let canceledString = reduceArray(canceled,"date_new");

  const setMonth = (e, inc, blur)=>{
    e.preventDefault();
    setActiveDate((date)=>{
    let newDate = new Date(date.getYear()+1900,date.getMonth() + inc,1);
      if(inc<0 && newDate<todayDate) return todayDate
    else return newDate;
  });
    if(blur) e.target.blur();
}

  useEffect(() => {
    const empty = Array(monthDays).fill({});
    setDays(empty.map((item, i) => <Day
      key={i+1}
      id={i+1}
      setHoveredDate={setHoveredDate}
      setDateChecked={setDateChecked}
      activeDate={activeDate}
      dateChecked={dateChecked}
      className={(i<activeDay-1)?"innactive":(i===activeDay-1)?"active":""}
      eventsString={eventsString}
      scheludedDays={scheludedDays}
      canceledString={canceledString}
      />))

  }, [monthDays, activeDay, setHoveredDate, activeDate, eventsString,setDateChecked,dateChecked, scheludedDays,canceledString])

  return (
    <div className={`calendar-outer`}>
      <div className="wrapper">
      <div className="month-title">
        {(activeDate > todayDate)?<button
          aria-label="Poprzedni miesiąc"
          title="Poprzedni miesiąc"
          tabIndex={dateChecked? "-1" : "0"}
          className="change-month"
          onPointerDown={(e)=>setMonth(e,-1,true)}>
          {`<`} </button>:<div></div>}
        {monthsList[activeDate.getMonth()]}
        <button
          aria-label="Następny miesiąc"
          title="Następny miesiąc"
          tabIndex={dateChecked? "-1" : "0"}
          className="change-month"
          onPointerDown={(e)=>setMonth(e,1,true)}> {`>`}</button>
        <div>{(activeDate.getYear()+1900)}</div>
      </div>
      <div className="calendar-inner">

        {Days}
      </div>
      </div>
      <div className="wrapper-nearest">
        <div className="nearest-event-label">Najbliższe wydarzenie</div>
        <div className="nearest-event">
          <Day dateChecked={dateChecked} setDateChecked={setDateChecked} className="nearest-day" eventsString={eventsString} activeDate={nearestEvent ? new Date(Date.parse(nearestEventDate)): null} setHoveredDate={setHoveredDate} id={nearestEvent ? nearestEventDate.slice(nearestEventDate.length-2,nearestEventDate.length): null} label={nearestEvent ? <div><div className="title">{nearestEvent.tytul}</div>{nearestEventDate}</div>: "Brak nadchodzących wydarzeń"}/>
        </div>
    </div>
    </div>
  )
}

export default Calendar
