import React from 'react'
import {Switch, Route} from "react-router-dom";
import Home from "./home"
import About from "./about"
import Bulletin from "./bulltin"
import Photos from "./photos"
import NotFound from "./not-found"
import Article from "../common/article"


import './main.css';

const Main = ({className,appStore}) => {
  return (
    <main className={className?className:""}>
      <Switch>
    <Route exact path={["/","/strona-glowna","/strona-glowna/:id"]}>
        <Home appStore={appStore}/>
    </Route>
    <Route exact path="/biuletyn"  children={<Bulletin appStore={appStore} type={"aktualnosci"}/>}/>
    <Route exact path="/zdjecia"  children={<Photos appStore={appStore}/>}/>
    <Route exact path={["/o-nas/","/o-nas/:id"]}  children={<About appStore={appStore}/>}/>
    <Route exact path="/artykul/:id"  children={<Article appStore={appStore}/>}/>
    <Route exact path={["/aktualnosci/:id"]}  children={<Article appStore={appStore} type={"aktualnosci"} />}/>
    <Route exact path={["/biuletyny/:id"]}  children={<Article appStore={appStore} type={"biuletyn"} />}/>
    <Route exact path={"*"}  children={<NotFound appStore={appStore}/>}/>
  </Switch>
  </main>
  )
}

export default Main
