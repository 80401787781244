import React from 'react'
import Banner from "./banner"
import News from "../../common/news"
import {useState} from "react"

import {useScrollRouting, useAppearing} from "../../hooks/custom-hooks"


const Home = ({appStore}) => {
  const  [rendered,setRendered] = useState(false)
  useAppearing({appStore, isFullyRendered:rendered});
  useScrollRouting({locationPath:"strona-glowna",isFullyRendered:rendered});

  return (
    <div className="homepage">
      <section id="info" data-name="banner informacyjny"><Banner appStore={appStore} /></section>
      <section id="aktualnosci" data-name="aktualności">
        <News setRendered={()=>setRendered(true)} appStore={appStore} filters={{"filter[glowna]":true}}/>
      </section>
    </div>
  )
}

export default Home
