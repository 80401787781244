import {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import {enableScroll} from "../utils"


const useTransition = (data,transitionTime) => {
  const [content,setContent] = useState();

  useEffect(()=>{
    let timeout1
      if(data){
        timeout1 = setTimeout(()=>setContent(data),transitionTime)
      }
      return ()=>{
        clearTimeout(timeout1)
      };
  },[data,setContent,transitionTime])
  return content;
}

export {useTransition};

const useDeviceHeight = () => {
  const [deviceHeight,setDeviceHeight] = useState(window.screen.height);

  useEffect(()=>{
    const appHeight = () => {
      if(window.innerWidth < 1024) setDeviceHeight(window.screen.height)
      else setDeviceHeight("100vh")

    }
    window.addEventListener("resize", appHeight)
    return ()=> window.removeEventListener("resize", appHeight);
  },[setDeviceHeight])

  return deviceHeight;
}

export {useDeviceHeight};

const useRendered = ({renderedObject,error,setRendered}) => {
  useEffect(
    ()=> {
      if((setRendered!==undefined) && (renderedObject!==null || error) && !(Array.isArray(renderedObject) && renderedObject.length===0)) {
        setRendered()
      }
    },[renderedObject,error,setRendered]
  )
}
export {useRendered};

const useAppearing = ({appStore,isFullyRendered}) =>{

  useEffect(()=>{
    if(appStore){
    let counter;
    counter = setTimeout(()=>{
        appStore.toggleLoading(false);
        appStore.setContentLoaded(false);
        enableScroll()
    }, 8000);
    let to = appStore.isContentLoaded ? appStore.isContentLoaded.to : null;
    let sectionId = appStore.isContentLoaded ? appStore.isContentLoaded.sectionId : null;
    let timeout;
    if((to || sectionId) && isFullyRendered && appStore.isLoading){
      clearTimeout(counter);
      timeout = setTimeout(function () {
            let tempId = sectionId;
            if(!sectionId){
              let toArray =to.split("/")
              tempId = isNaN(toArray[2]) && toArray[2] ? toArray[2].toLowerCase().replace(/ /g, "") : undefined;
            }

        let section = document.querySelector('#'+tempId)
        let sectionY = section ? section.getBoundingClientRect().top + window.scrollY : 0
        appStore.toggleLoading(false);
        if(section) {window.scrollTo(0,sectionY  - 50 )}
        else {
          window.scrollTo(0, 0);
        }
        enableScroll()
        appStore.setContentLoaded(false);
      }, 500);

    }
    return ()=>{
      clearTimeout(timeout)
      clearTimeout(counter)
    }
  }
  },[appStore, isFullyRendered])
}

export {useAppearing};

const useScrollRouting = ({locationPath,isFullyRendered}) => {

  const history = useHistory();
  const [sections,setSections] = useState([]);
  const [sectionsMapped,setSectionsMapped] = useState([]);
  const [activeSection,setActiveSection] = useState();

  useEffect(()=>{
    if(isFullyRendered)
      {
        let sectionsTmp = Array.from(document.querySelectorAll('section'))
        setSections(sectionsTmp);
        setSectionsMapped(sectionsTmp.map((section)=>({
          id:section.id,
          name:section.getAttribute("data-name")
        })).filter((sectionMapped)=>sectionMapped.id))
        const location = window.location.pathname.split("/")
        const paramSection = location[2] ? document.querySelector('#' + location[2]) :null;
        if(paramSection){
          window.scrollTo(0, paramSection.getBoundingClientRect().top + window.scrollY  - 50)
        }
      }
  },[setSections,isFullyRendered])

  useEffect(()=>{
    if(isFullyRendered){
    const handleScroll = (sections,setActiveSection,currentActive,history)=>{
      const sectionsBounds = sections.map(
      section => section.getBoundingClientRect())

      const sectionIndex = sectionsBounds.findIndex((bound)=>bound.top+bound.height - 100>0);
      let activeSection = sections[sectionIndex] ? sections[sectionIndex].id : "";

        if(currentActive!==activeSection) {
          setActiveSection(activeSection);
          window.history.replaceState(null, "", "/" + locationPath + "/"+ activeSection)
        }
  };

    const scrollListner = ()=> handleScroll(sections,setActiveSection,activeSection,history);;
    document.addEventListener("scroll", scrollListner);
    return ()=>document.removeEventListener("scroll", scrollListner);}
  },[sections,activeSection,history,locationPath,isFullyRendered])
  return {sections:sectionsMapped,activeSection:activeSection};
}


export {useScrollRouting};

const useScrollToTop = ({disable})  => {
  if(!disable) window.scrollTo(0, 0);
}

export {useScrollToTop};
