
import React from 'react'
import "./tile.css";
import {useState, useEffect} from "react"
import {useFetchFromDB} from "../../../hooks/db-hooks.js"
import {getImgUrl,getSrcSet} from "../../../utils"


import DelayLink from "../../delay-link"
import {useHistory} from "react-router-dom";

const Tile = ({endPointName,orderNr,appStore,filters,setNewsLoading,count,setRendered, style}) => {
  const history = useHistory();
  const [loadingState,setLoadingState] = useState("loading");
  const tileFilters = {...filters, "sort[_created]":-1}
  const [content,error] = useFetchFromDB({endPoint:endPointName, orderNr:orderNr, contentName:0, filters:tileFilters})

  useEffect(()=>{
    if(content){
      if(orderNr===count-1){
        setRendered(0);
        setRendered(1);
        setRendered(2);
      } else setRendered(orderNr%3);
    }
  },[content,count,setRendered,orderNr])
  return (
    <DelayLink ariaLabel={!content?"":content.tytul}
               appStore={appStore}
               history={history}
               delay={500}
               className={loadingState}
               to={content && content.artykul && content.artykul.display ? `/artykul/${content.artykul.display}` : (content ? `/aktualnosci/${content.tytul_slug}` : "")}
               inner={<div style={style} className={`tile ${loadingState}`}>
               <div className="tile-inner">

               {content ?
                   <img
                   className={`main-image${content.dopasuj_obraz?" fitted":""}`}
                   alt={content.obraz.description ? content.obraz.description :"Obrazek do aktualności - Klub Seniora"}
                   srcSet={getSrcSet({content:content.thumbnail?content.thumbnail:content.obraz})}
                   src={getImgUrl({content:content.thumbnail?content.thumbnail:content.obraz})}
                   sizes="(min-width: 1024px) 500px,"
                   onLoad={()=>{
                    if(orderNr%3===0 || orderNr===count){
                      setNewsLoading("")
                    }
                     return setLoadingState("loaded")}
                   }
                   onError={()=>{
                     if(orderNr%3===0 || orderNr===count){
                       setNewsLoading("")
                     }
                     return setLoadingState("error")
                   }}
                   />:""
               }
               {content ?<div className="background" style={content && content.dopasuj_obraz ?
                   {
                     backgroundImage:`url(${getImgUrl({content:content.thumbnail?content.thumbnail:content.obraz,size:10})})`
                   }:undefined}>
               </div>:""}
                {content ?
                 <div className="description">
                   <h3 className="title">{!content?"":content.tytul}</h3>
                   <div className="caption">{!content?"":content.opis}</div>
                   {/*<div className="created-at">{content ? new Date(content._created*1000).toLocaleString():""}</div>*/}
                   </div>
                 :""}
                </div>

               </div>
             }>
        </DelayLink>


  )
}

export default Tile
