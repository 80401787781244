import React from 'react'
import "./error.css"

const Error = ({e}) => {
  return (
    <div className="error-msg">
            <p className="title">Niestety, nie udało się pobrać zasobu. Spróbuj odświeżyć stronę - jeśli problem będzie się powtarzał skontaktuj się z znami.
            </p>
    </div>
  )
}

export default Error
