import React from "react";
import Article from "../../common/article";
import DelayLink from "../../common/delay-link";
import { useAppearing } from "../../hooks/custom-hooks";
import { useFetchFromDB } from "../../hooks/db-hooks.js";
import { useHistory } from "react-router-dom";
import "./bulletin.css";


const Bulletin = ({ appStore }) => {
  const history = useHistory();

  const [collections] = useFetchFromDB({
    endPoint: "collections/entries/biuletyn", filters:{"sort[_created]":-1}
  });
  const isFullyRendered = collections!==undefined

  const subMenu = collections && collections.length > 1 ? (
    <ul >
      {collections.slice(1).map((item, i) => (
        <li key={i}>
          <DelayLink
            appStore={appStore}
            history={history}
            delay={500}
            to={`/biuletyny/${item.tytul_slug}`}
            inner={item.tytul}
          ></DelayLink>
        </li>
      ))}
    </ul>
  ) : <ul><li>Brak archiwalnych biuletynów</li></ul>;


  useAppearing({ appStore, isFullyRendered });

  return (
    <div className="bulletin">

    {collections && collections[0] ? <Article
      noParams={true}
      customContent={collections[0]}
      type="biuletyn"
      loading="true"
    /> : ""}
    <div className={"bulletin-list underlined-links"}>
      <h2>Archiwum</h2>
      {subMenu}
      </div>
    </div>
  );
};

export default Bulletin;
