import React from 'react'
import "./schelude.css"
import {getImgUrl} from "../../../../utils"
import {useEffect,useState} from "react"
import DelayLink from "../../../../common/delay-link"
import Loading from "../../../../common/loading"
import {useHistory} from "react-router-dom";
const weekNames = ["Niedziela","Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"]

const dbNames = {
  0:"niedz",
  1:"pon",
  2:"wt",
  3:"sro",
  4:"czw",
  5:"pt",
  6:"sob"
}

const Row = ({start, end, canceled, todayDate, opis ,moved, _id, place}) => {

const canceledIndex = canceled && canceled.length > 0 ?
canceled.findIndex((cancelEntry)=>  {

  return cancelEntry.zajecia._id ===_id && cancelEntry.date_old === todayDate
}) : false

const isMoved = canceledIndex!==false && canceledIndex > -1 && canceled[canceledIndex].date_new;
  return (
    <div className={`row ${canceledIndex!==false && canceledIndex > -1 ? "canceled" :""} ${isMoved ? "moved" :""}`}>
    <div className="row-title">{start + " - " + end}</div>
      <span className="row-name">{opis} {place ? ", " + place : ""}</span>
      {canceledIndex!==false && canceledIndex > -1 ? <strong className="canceled-after"> - odwołane </strong> :""}
      {isMoved ? <strong className="canceled-after"> - przeniesione na {canceled[canceledIndex].date_new} {canceled[canceledIndex].start_time_new} {canceled[canceledIndex].place_new}</strong> :""}
    </div>
  )
}

const Event = ({tytul, opis, obraz, kalendarz, history, appStore, content,godzina}) => {

  const [loadingState,setLoadingState] = useState("loading");
  const inner =
  <div className="event">
    <div className="event-summary">
      <div className="event-text">
      <h3>{tytul + " - " + kalendarz + " " + (godzina ?godzina : "")}</h3>
      <p>{opis}</p>
      </div>
      <div className="img-container">
        <img
             className={loadingState}
             onLoad={()=>setLoadingState("loaded")}
             onError={()=>setLoadingState("error")}
             src={getImgUrl({content:obraz,size:200})} alt="Obrazek do wydarzenia"/>
           <Loading className={"white-color "+loadingState}/>
      </div>
    </div>

  </div>
  return  <DelayLink appStore={appStore} history={history} delay={500} to={content && content.artykul && content.artykul.display ? `/artykul/${content.artykul.display}` : (content ? `/aktualnosci/${content.tytul_slug}` : "")} inner={inner}/>
}

const Schelude = ({hoveredDate, events, appStore, canceled, scheludeEx}) => {
  const [Rows,setRows] = useState([]);
  const [TodayEvents,setTodayEvents] = useState([]);
  const dayOfweek = hoveredDate ? hoveredDate.getDay() : null;
  const dayOfMonth = hoveredDate ? hoveredDate.getDate() : null;
  const localeDate = hoveredDate ? `${hoveredDate.getYear() + 1900}-${hoveredDate.getMonth() < 9 ? "0"+(hoveredDate.getMonth()+1).toString() : hoveredDate.getMonth()+1}-${dayOfMonth < 10 ? "0"+dayOfMonth.toString() : dayOfMonth}` : undefined;

  const history = useHistory();
  const movedFiltered = canceled && hoveredDate && canceled.length > 0 ? canceled.filter((movedEntry)=> movedEntry.date_new && movedEntry.date_new===localeDate) : undefined
  const movedDiv = movedFiltered ? <div>{ movedFiltered.map((movedEntry,i)=> <div key={i} className="moved-to"><h3>PRZENIESIONE Z INNEGO DNIA</h3><div className="row-title">{movedEntry.start_time_new} - {movedEntry.end_time_new}</div>{movedEntry.zajecia.opis} - przeniesione z {movedEntry.date_old} {movedEntry.place_new}</div>) }</div> : ""
 useEffect(()=>{
  if(scheludeEx){
   const data = scheludeEx  && (dayOfweek!==null) ?  scheludeEx.filter((ex)=>ex.dzien === dbNames[dayOfweek]) : null;
  if(data){
     let sortedData = data.sort((itemA,itemB)=>parseInt(itemA.start) - parseInt(itemB.start))
     let rows = sortedData.map((item,i) => <Row {...item} canceled={canceled} todayDate={localeDate} key={i}/>)
     setRows(rows)
   }
 }
},[scheludeEx,setRows,dayOfweek,hoveredDate,canceled,localeDate])

useEffect(()=>{
  if(events && hoveredDate){
    setTodayEvents([])
    events.forEach((item, i) => {
      if(item.kalendarz === localeDate)
      {
          setTodayEvents((array) => array.concat([<Event appStore={appStore} history={history} key={i} {...item} content={item}/>]))
      }
    });
  }
} ,[events,hoveredDate,localeDate,history,appStore])

  return (
    <div className="schelude-inner">
        <div className="wrapper daily-schelude">

          <h2>{dayOfweek!==null?weekNames[dayOfweek] + " " + localeDate:"Spotkania w dniu"}</h2>
          {(movedFiltered===undefined || movedFiltered.length===0) && Rows.length === 0 && TodayEvents.length === 0 ? <h2>Brak spotkań i wydarzeń</h2>: ""}
          {Rows.length > 0 ? Rows : ""}
          {movedDiv ? movedDiv : ""}
        </div>
        {TodayEvents.length > 0 ? <div className="wrapper event-schelude">{TodayEvents}</div>: ""}

    </div>
  )
}


export default Schelude
