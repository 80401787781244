import React from "react";

import DelayLink from "../../common/delay-link";
import UiMenu from "../../common/ui-menu";
import { useFetchFromDB } from "../../hooks/db-hooks.js";

import "./nav.css";
const Navigation = ({
  className,
  appStore,
  history,
  setNavState,
  location,
  setTheme,
}) => {
  const [collections] = useFetchFromDB({
    endPoint: "collections/entries/onas",
  });

  const props = {
    location: location,
    appStore: appStore,
    history: history,
    delay: 500,
  };
  const subMenu = collections ? (
    <ul className={"nav-sub nav"}>
      {collections.map((item, i) => (
        <li key={i}>
          <DelayLink
            {...props}
            to="/o-nas"
            sectionId={item.nazwa_slug}
            inner={item.nazwa}
          ></DelayLink>
        </li>
      ))}
    </ul>
  ) : undefined;
  return (
    <nav className={className}>
      <ul className={"nav-main nav"} onClick={() => setNavState(-1)}>
        <li>
          <DelayLink
            {...props}
            to="/strona-glowna"
            inner=<span>Strona główna</span>
          ></DelayLink>
        </li>
        <li>
          <DelayLink
            {...props}
            to="/strona-glowna"
            sectionId="aktualnosci"
            inner="Aktualności"
          ></DelayLink>
        </li>
        <li className={"li-with-sub"}>
          {subMenu}
          <DelayLink {...props} to="/o-nas" inner="O nas"></DelayLink>
        </li>
        <li>
          <DelayLink {...props} to="/biuletyn" inner="Biuletyn"></DelayLink>
        </li>
        <li>
          <DelayLink {...props} to="/zdjecia" inner="Zdjęcia"></DelayLink>
        </li>
        <li>
          <DelayLink
            {...props}
            to="/o-nas"
            sectionId="kontakt"
            inner="Kontakt"
          ></DelayLink>
        </li>
        <li>
          <UiMenu setTheme={setTheme} />
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
