import React from 'react'
import { useParams } from "react-router-dom";
import "./article.css"
import ReactPlayer from 'react-player/lazy'
import ReactHtmlParser, {convertNodeToElement}  from 'react-html-parser'
import Loading from "../../common/loading";
import { useFetchFromDB } from "../../hooks/db-hooks.js"
import { getImgUrl, addPrefix } from "../../utils"
import { useTransition, useScrollToTop, useRendered, useAppearing } from "../../hooks/custom-hooks.js"
import Gallery from "../gallery"

const Article = ({appStore, type, customContent, noParams, firstRight, paragrahName,setRendered, noCreatedAt,disableScrollTop }) => {
  const parName = paragrahName ? paragrahName : "akapit"
  useScrollToTop({ disable: noParams || disableScrollTop });
  let { id } = useParams();
  const endPointName = `collections/entries/${type ? type : "artykul"}`

  const [articleData, error] = useFetchFromDB({customContent:customContent, endPoint: endPointName, filters: type==="grupy" ? {simple:true, "filter[nazwa_slug]":id} : {simple:true, "filter[tytul_slug]":id} });
  const transitionContent = useTransition(articleData, 500);
  const content = Array.isArray(transitionContent) && transitionContent.length>0 ? transitionContent[0] : transitionContent;
  useRendered({renderedObject:articleData,error:error,setRendered:setRendered})
  useAppearing({appStore:appStore,isFullyRendered:true});

  const textGenerator = (paragrahArray, title) => {
    if (Array.isArray(paragrahArray) && paragrahArray.length > 0) {
      return paragrahArray.map((paragrah, index) =>
        <div key={index} className="paragrah-wrapper">
          <div key={index} className={`paragrah ${(index%2!==0 && !firstRight) || (index%2===0 && firstRight) ? "right" : "left"}`}>
          <div className="text-wrapper">
            <div>
            {(paragrah.value.tytul && index>0) || (paragrah.value.tytul && !content.tytul)
                ? <h2>{paragrah.value.tytul}</h2> : (index===0 ? <h1>{content.tytul}</h1> : "")}
              <div className="par"> {ReactHtmlParser(paragrah.value.tresc, {transform:function transform(node, index){

                if (node.type === 'tag' && node.name === 'a') {
                    if(node.attribs) {
                      node.attribs.target = "_blank";
                      node.attribs.rel = "noreferrer noopener";
                      node.attribs.class="cms-link"
                    }
                    return convertNodeToElement(node, index, transform);
                }
                if (node.type === 'tag' && node.name === 'script') {
                    return null;
                }
              }})}</div>
            </div>
          </div>
          {(paragrah.value.obraz) ?
            <Gallery singleImg={paragrah.value.obraz} isFitted={paragrah.value.dopasuj_obraz} />:""}
          </div>
          {(paragrah.value.video || paragrah.value.url) ?
            <div className="player-wrapper">
              <div className="player">
                <ReactPlayer width='100%' height='100%' url={paragrah.value.video ? getImgUrl({content:paragrah.value.video,video:true}) : paragrah.value.url} controls={true}
                  />
              </div>
            </div>
              :
              ""}
        </div>)
    } else {
      return ""
    }
  }

  return [<article key="article" className={(articleData&&!content)?"loading":""}>
      <section>
        {(content) ? (type==="aktualnosci" || type==="biuletyn" || type==="grupy" ?
          <div className={`paragrah ${(firstRight) ? "right" : "left"}`}>
            <div className="text-wrapper">
              <div>
                {content.tytul ? <h1>{content.tytul}</h1> : ""}
                {content.nazwa ? <h1>{content.nazwa}</h1> : ""}
                <p>{content.opis}</p>
              </div>
          </div>
          {content.obraz && type!=="biuletyn" ? <Gallery singleImg={content.obraz} /> :""}
          {(content.pdf) ?
              <a className={`bulletin-wrapper ${content.obraz ? "image-ok" : "svg-ok"}`} title="Zobacz biuletyn" onClick={(e)=>{e.target.blur()}} target="_blank" href={addPrefix(undefined,content.pdf.path,true,false)} rel={"noopener noreferrer"}>{content.obraz ? <Gallery preventBubble={true} singleImg={content.obraz} /> :<span>Obejrzyj biuletyn</span>}</a>:""}
          {content.logo ? <Gallery singleImg={content.logo} /> :""}
          </div> : textGenerator(content[parName])): error ? error.message:""}
      </section>

      {content && content.galeria && Array.isArray(content.galeria) && content.galeria.length>0?
        <section id="gallery">
          <h2>Galeria</h2>
          <div className="gallery-wrapper">
            <Gallery imgArray={content.galeria}/>
          </div>
        </section>:""}
        {(noParams || noCreatedAt) ? undefined : <div className="created-at">{content && !Array.isArray(content) ? "Dodano " + new Date(content._created * 1000).toLocaleString():""}</div>}
    </article>, <Loading key="loading" className={!content ?(error?"error":"loading"):"loaded"}/>]
}

export default Article
