import React from 'react'
import "./ui-menu.css"

const UiMenu = ({setTheme, visableLabels}) => {
  return (
    <div className="ui-container">
    <div className="ui-menu">
      <button onClick={(e)=>{
            e.target.blur();
            setTheme(prev => {
              return {
                ...prev,
                  grey:!prev.grey}
          })

        }} className="contrast-btn" title="Zmień kontrast" aria-label="Zmień kontrast">
        {visableLabels? "Zmień kontrast" :""}
      </button>
      <button onClick={(e)=>{
            e.target.blur();
            setTheme(prev => {
              return {
                ...prev,
                  fontSize:prev.fontSize+1}
          })

        }} className="font-plus-btn" title="Zwiększ czcionkę" aria-label="Zwiększ czcionkę">
        {visableLabels? "Zwiększ czcionkę" :""}
      </button>
      <button onClick={(e)=>{
            e.target.blur();
            setTheme(prev => {
              return {
                ...prev,
                  fontSize:prev.fontSize-1}
          })

        }}  className="font-minus-btn" title="Zmniejsz czcionkę" aria-label="Zmniejsz czcionkę">
            {visableLabels? "Zmniejsz czcionkę" :""}
      </button>
    </div>
    </div>
  )
}

export default UiMenu;
