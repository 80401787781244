import React from 'react';
import {useState} from "react"

import './app.css';
import {BrowserRouter as Router} from "react-router-dom";

import Header from "./header"
import Loading from "./common/loading"
import Footer from "./footer"
import Main from "./main"

function App() {
  const [isLoading,setLoading] = useState(false)
  const [isContentLoaded,setContentLoaded] = useState(false)
  const [theme,setTheme] = useState({
    grey:false,
    fontSize:0
  })

  const toggleLoading = (bool)=>setLoading( value => bool!==undefined || bool!==null ? bool : !value )

  return (
    <div className={`app${theme.grey ? " greyscale":""} f-size${theme.fontSize}${isLoading?" loading":""}`}>
           <Router>
            <Header isContentLoaded={isContentLoaded} setTheme={setTheme} appStore={{setContentLoaded,isContentLoaded,toggleLoading}}/>
            <Main isContentLoaded={isContentLoaded} setContentLoaded={setContentLoaded} appStore={{setContentLoaded,isContentLoaded,toggleLoading,isLoading}}/>
            <div className="loading-main"><Loading className={isLoading?"loading":"loaded"}/>Ładuję ...</div>
            <Footer setTheme={setTheme} appStore={{setContentLoaded,isContentLoaded,toggleLoading}}/>
         </Router>
    </div>
  );
}

export default App;
