import React from "react";
import Article from "../../common/article";
import Contact from "../../common/contact";
import { useAppearing, useScrollRouting } from "../../hooks/custom-hooks";
import { useFetchFromDB } from "../../hooks/db-hooks.js";

import "./about.css";
import { useState, useEffect } from "react";

const About = ({ appStore }) => {
  const  [renderedArray,setRenderedArray] = useState("000000000")
  const isFullyRendered = !renderedArray.includes("0")
  const [collections] = useFetchFromDB({
    endPoint: "collections/entries/onas",
    filters: { populate: 1 },
  });
  const setRenderedOne = (number) => setRenderedArray((str) => {
    let stringArray = str.substr(0, number) + "1" + str.substr(number + 1);
    return stringArray;
  })
  let parsInLast = 2;
  const articles = collections
    ? collections.map((section, i) => {
      let sect = <section key={i} id={section.nazwa_slug} data-name={section.nazwa_slug}>
        <Article
          noParams={true}
          customContent={section.artykul}
          loading="true"

          firstRight={parsInLast % 2 === 1}
          setRendered={()=>setRenderedOne(i)}
        />
      </section>
      parsInLast= section.artykul && section.artykul.akapit ? section.artykul.akapit.length : 1;
        return sect;
      })
    : undefined;

  useScrollRouting({locationPath:"o-nas",isFullyRendered:isFullyRendered});
  useAppearing({ appStore, isFullyRendered });
  useEffect(()=>{
    if(collections){
      let newString = Array(collections.length+1).join('0')
      setRenderedArray(newString)
    }

  },[collections])

  return (
    <div className="about">
      {articles}
      <section className="underlined-links" id="kontakt">
        <article className="contact-art">
          <Contact showTitle={true} />
        </article>
      </section>
    </div>
  );
};

export default About;
