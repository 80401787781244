import React from 'react'
import "./day.css"
import {useState,useEffect} from "react"

const dbNames = {
  0:"niedz",
  1:"pon",
  2:"wt",
  3:"sr",
  4:"czw",
  5:"pt",
  6:"sob"
}


const Day = ({id, className,setHoveredDate,activeDate, eventsString, dateChecked, setDateChecked, label, scheludedDays, canceledString}) => {
  const [checked,setChecked] = useState(false);
  const dateString = activeDate ? `${activeDate.getYear() + 1900}-${activeDate.getMonth() < 9 ? "0"+(activeDate.getMonth()+1).toString() : activeDate.getMonth()+1}-${id < 10 ? "0"+id.toString() : id}` : undefined;
  const dayDate = activeDate ? new Date(activeDate.getYear()+1900,activeDate.getMonth(),id): null;
  const dayOfWeek = dayDate ? dbNames[dayDate.getDay()] : "";
  eventsString = eventsString ? eventsString.split(" ") : [];
  const isEvent = () => dayDate && eventsString.includes(dateString) ? " event-true" :" event-fase"
  const isMoved = () => canceledString && dayDate && canceledString.includes(dateString) ? " moved-true" :" event-fase"
  const isEx = () => activeDate && scheludedDays && scheludedDays.includes(dayOfWeek) ? " ex-true" :" ex-fase"

  useEffect(()=>{
    if(dateChecked===false) setChecked(false);
  },[setChecked,dateChecked])

  return (
    <div
      title="Kliknij aby wybrać datę"
      onKeyDown={(e) =>{
        if(e.key === 'Enter'){
          e.preventDefault();
          setDateChecked((bool)=>!bool)
          setChecked((bool)=>!bool)
          setHoveredDate(dateChecked?null:dayDate)
          }
        }

      } tabIndex={className==="innactive" || (dateChecked && checked===false)? "-1" : "0"} aria-label={`Kalendarz na dzień ${dateString ? dateString: id}`} className={`day ${className}${isEvent()}${isMoved()}${isEx()}${checked?" this-day-checked":""}`}
       onClick={(e)=>{
         e.preventDefault();
        setDateChecked((bool)=>!bool)
        setChecked((bool)=>!bool)
        setHoveredDate(dateChecked?null:dayDate)
        e.target.blur();
      }
      }
       data-date={dateString ? dateString:""} onPointerLeave={()=>checked?null:setHoveredDate(null)} onPointerEnter={()=>checked?null:setHoveredDate(dayDate)}>
    <span>{label ? label : id}</span>
    <span className="dayOfWeek">{dayOfWeek}</span>
    </div>
  )
}

export default Day
