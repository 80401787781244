import React from "react";
import Gallery from "../../common/gallery";
import { useAppearing } from "../../hooks/custom-hooks";
import { useFetchFromDB } from "../../hooks/db-hooks.js";
import "./photos.css";

const Photos = ({ appStore }) => {
  const [collections] = useFetchFromDB({
    endPoint: "collections/entries/artykul",
    filters: { "filter[galeria]": true, "sort[_created]":-1 },
  });
  const isFullyRendered = collections !== undefined;

  const galeries = collections ?
      collections.map((item, i) => (
          <div key={i} className="photos-container">
            <Gallery imgArray={item.galeria} />
            <div className="description"><h3>Galeria - {item.tytul}</h3>{(new Date(item._created * 1000 )).toLocaleDateString()}</div>
          </div>

      ))

   : undefined;

  useAppearing({ appStore, isFullyRendered });

  return <div className="photos">
  {galeries}
  </div>;
};

export default Photos;
