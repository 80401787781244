import React from 'react'
import "./footer.css";
import DelayLink from "../common/delay-link"
import {useHistory} from "react-router-dom";
import Contact from "../common/contact"

const Footer = ({appStore, setTheme}) => {
  const genSocial = () =>
  {
    if(window.Configs && window.Configs.socialMediaMenu && window.Configs.socialMediaMenu.length > 0)
      return window.Configs.socialMediaMenu.map((item,i)=>
          <a key={i} href={item.url} target="_blank" rel="noopener noreferrer" title={item["aria-label"]} aria-label={item["aria-label"]}><div style={{backgroundImage: `url("${item.imagePath}")`}}></div></a>)
  }

  const history = useHistory();
  return (
    <footer className="underlined-links">
      <div className="city-container">
        <div className={"contact-wrapper"}>
          <Contact/>
        </div>
        <div className="social">
          {genSocial()}
        </div>

      </div>
      <div className="right-wrapper underlined-btns">
          <div className="menu1 menu-footer">
            <h3 className="footer-title information">Ważne informacje</h3>
            <ul>
              {(window.Configs.importantInfo && window.Configs.importantInfo.length > 0) ? window.Configs.importantInfo.map((item, i)=>
                <li key={i}>
                  <a href={item.url} target={item.newPage ? "_blank" : undefined} rel="noopener noreferrer" title={item.name}>{item.name}</a>
                </li>) : ""}
              <li>
                <DelayLink history={history} delay={500} to="/o-nas" sectionId="kontakt" inner="Kontakt" appStore={appStore}></DelayLink>
              </li>
            </ul>
          </div>
          <div className="fav-wrapper">
            <h3 className="footer-title information">Polecane strony</h3>
              <ul>
                <li>
                  <a href="https://www.gabin.pl" target="_blank" rel="noopener noreferrer" title="Miasto i Gmina Gąbin">Strona Miasta i Gminy Gąbin</a>
                </li>
                {(window.Configs.footerUrls && window.Configs.footerUrls.length > 0) ? window.Configs.footerUrls.map((url,i)=>
                  <li key={i}>
                    <a href={url.url} target="_blank" rel="noopener noreferrer" title={url.name}>{url.name}</a>
                  </li>) : ""}
              </ul>
          </div>


      </div>
      <div className="copyright">
        Gąbiński Klub Seniora © 2021
      </div>
    </footer>
  )
}

export default Footer
