import qs from "qs";

export const addPrefix = (options,src,video,isGallery) => {
  let uploadedPath = (isGallery?"":"storage/uploads") + src;

  return process.env.REACT_APP_HOST_URL + (!video?  ("api/cockpit/image?" + qs.stringify({
  token:process.env.REACT_APP_PUBLIC_TOKEN,
  ...options,src:uploadedPath})
):uploadedPath);
}

const getImgUrl = ({content, size, video,isGallery} ) =>{
  const options =!video ? {q:window.Configs.imageQuality, m:"bestFit",w:(size || 1500),h:"auto", o:1} : {}
  if(content && content.path) return addPrefix({...options},content.path,video,isGallery)
  else return "/img/imgNotFound.svg"
}

export {getImgUrl};


const getSrcSet = ({content,sizes,isGallery}) =>{

  if(content && content.path){
    return addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[0] : 300),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "300w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[0] : 400),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "400w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[0] : 500),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "500w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[1] : 750),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "750w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[2] : 1000),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "1000w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[3] : 1250),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "1250w")+"," +
           addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[3] : 1500),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "1500w")+","}
  return "";
}

const linkGenerator = ({content})=>{
  if(content){
    return content["dodaj_artykul"] ? "/article/"+content["dodaj_artykul"]["id"] : "/aktualnosci/"+content.id
  }
  else return ""
}


export {getSrcSet, linkGenerator};


var keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
  e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
  if (keys[e.keyCode]) {
    preventDefault(e);
    return false;
  }
}

// modern Chrome requires { passive: false } when adding event
var supportsPassive = false;
try {
  window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
    get: function () { supportsPassive = true; }
  }));
} catch(e) {}

var wheelOpt = supportsPassive ? { passive: false } : false;
var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

// call this to Disable
function disableScroll() {
  window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll() {
  window.removeEventListener('DOMMouseScroll', preventDefault, false);
  window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  window.removeEventListener('touchmove', preventDefault, wheelOpt);
  window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

export {disableScroll, enableScroll};

// addPrefix({q:window.Configs.imageQuality, m:"bestFit",w:(sizes ? sizes[4] : 1600),h:"auto", o:1}, content.path,undefined,isGallery) +" " + (sizes ? sizes[0] : "1600w")+","}
